import { $fetch, $post, $put } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/mail/templates`, params);
}

export function validate(model) {
  return $post(`customer/mail/template/validate`, model);
}

export function create(model) {
  return $put(`customer/mail/template`, model);
}

export function update(model) {
  return $post(`customer/mail/template/${model.id}`, model);
}
