<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :sub-name="$t('template')">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="isEdit || disabled" />
        <n-field-enum v-model="model.type" field-name="type" enum-name="CustomerMailType" label="type" :error="error.type" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="isEdit || disabled" />
        <n-field-text v-model="model.sender" field-name="sender" label="sender" :error="error.sender" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-boolean v-model="model.html" field-name="html" label="html" :error="error.html" :disabled="disabled" />
        <n-field-text v-model="model.replyTo" field-name="replyTo" label="replyTo" :error="error.replyTo" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <n-field-text v-model="model.bcc" field-name="bcc" label="bcc" :error="error.bcc" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.subject" field-name="subject" label="subject" :error="error.subject" :disabled="disabled" />
      </n-row-col1>
      <n-row-col2 v-if="model.html">
        <n-field-boolean v-model="model.wysiwyg" field-name="wysiwyg" label="wysiwyg" :error="error.wysiwyg" :disabled="disabled" />
        <n-field-boolean v-model="model.preview" field-name="preview" label="preview" :error="error.preview" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <n-text-editor v-model="model.content" :html="model.html && model.wysiwyg" field-name="content" label="content" :error="error.content" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1 v-if="model.preview && model.html">
        <n-field-preview v-model="model.content" field-name="preview" label="preview" :error="error.content" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { create, update, validate } from '@/api/customer/customer-customer-email-template';

export default NModal.extend({
  name: 'ModalCustomerEmailTemplate',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
