<template>
  <!-- prettier-ignore -->
  <index-content :breadcrumb-items="[$t(`menu.customer.customer_mail_template`)]">
    <template v-if="hasRW" #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-1">{{ $t('label.type') }}</th>
            <th class="col-weight-1">{{ $t('label.language') }}</th>
            <th class="col-weight-1">{{ $t('label.html') }}</th>
            <th class="col-weight-2">{{ $t('label.sender') }}</th>
            <th class="col-weight-4">{{ $t('label.subject') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-enums:CustomerMailType="row.type"></td>
            <td v-enums:Language="row.language"></td>
            <td>
              <n-icon-boolean :value="row.html" />
            </td>
            <td style="max-width:210px;" class="n-ellipsis" :title="row.sender">{{ row.sender }}</td>
            <td style="max-width:410px;" class="n-ellipsis" :title="row.subject">{{ row.subject }}</td>
            <td class="n-button-box">
              <nb-custom v-rw type="error" custom-icon="fas fa-clone" text="copy" @on-click="openCopy(row)" />
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import CustomerView from './view';
import {beanCopy} from "@/helpers/utils";
import ModalCustomerEmailTemplate from './ModalCustomerEmailTemplate';
import { fetch } from '@/api/customer/customer-customer-email-template';

export default CustomerView.extend({
  name: 'CustomerCustomerEmailTemplate',
  data() {
    return {
      preload: true,
      condition: {
        companyId: null,
      },
    };
  },
  methods: {
    doLoad() {
      let params = { companyId: this.condition.companyId };
      return fetch(params);
    },
    parse(rows) {
      this.records = rows;
    },
    openModal(model = {}) {
      this.createModal(ModalCustomerEmailTemplate, { model, on: this });
    },

    openCopy(o) {
      let clone = beanCopy(o);
      clone.id = undefined;
      clone.version = undefined;
      this.createModal(ModalCustomerEmailTemplate, { model: clone, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 800px;
  th.rw-action {
    width: 8.5rem;
  }
}
</style>
